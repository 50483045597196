.log__CTAbtn {
    width: 359px;
    height: 80px;
    background: linear-gradient(90deg, rgba(193, 251, 255, 1) 0%, rgba(0, 240, 255, 1) 100%);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.log__CTAbtn p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: black;
    -webkit-text-fill-color: initial;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.log__CTAbtn:hover p,
.log__CTAbtn:focus p
{
    color: var(--pink-color);
    transition: all 0.2s ease;
}

@media screen and (max-width: 600px) {
    .log__CTAbtn {
        width: 259px;
        height: 60px;
    }

    .log__CTAbtn p {
        font-size: 32px;
    }
}