.log__examples {
    font-family: var(--font-family);
}
.log__examples h1 {
    font-size: 64px;
    background: var(--font-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.log__examples h1 {
    width: 1200px;
    margin: 100px 61px;
}

@media screen and (max-width: 1920px) {
    .log__examples h1 {
        width: calc(100vw - 650px);
        margin: 80px 61px;        
    }
}

@media screen and (max-width: 1240px) {
    .log__examples h1 {
        width: calc(100vw - 200px);
        margin: 50px 31px;        
    }
}

@media screen and (max-width: 900px) {
    .log__examples h1 {
        width: calc(100vw - 100px);
        margin: 50px 31px;        
    }
}

@media screen and (max-width: 600px) {
    .log__examples h1 {
        width: calc(100vw - 65px);
        font-size: 48px;
    }    
}

/****************************************************************************/
.log__exmp {
    margin: 100px 61px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 11px;
}

@media screen and (max-width: 1920px) {
    .log__exmp {
        margin: 80px 61px;        
    }
}

@media screen and (max-width: 1240px) {
    .log__exmp {
        margin: 50px 31px;        
    }
}

@media screen and (max-width: 900px) {
    .log__exmp {
        margin: 50px 31px;        
    }
}

@media screen and (max-width: 600px) {
    .log__exmp {
        font-size: 48px;
    }    
}

.log__exmp-card {
    width: 263px;
    height: 142px;
    margin: 10px 0px;
    border-radius: 10px;
    /* border: 1px solid #00F0FF; */
}

.log__exmp-card:hover, 
.log__exmp-card:focus {
    background: var(--background-emph-grad);
}

.log__exmp-hbar {
    width: 108px;
    height: 7px;
    background: linear-gradient(90deg,  rgba(255, 7, 245, 1) 0%, rgba(0, 240, 255, 1) 100%);
    color: blueviolet;
    position: relative;
    margin: 11px;
}

.log__exmp-inner1 {
    position: relative;
    margin: 11px;
    left: 0px;

    font-family: 'Advent Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: white;
    -webkit-text-fill-color: initial;

}

/****************************************************************************/
.log__exmp1 {
    margin: 100px 61px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 11px;
}

@media screen and (max-width: 1920px) {
    .log__exmp1 {
        margin: 80px 61px;        
    }
}

@media screen and (max-width: 1240px) {
    .log__exmp1 {
        margin: 50px 31px;        
    }
}

@media screen and (max-width: 900px) {
    .log__exmp1 {
        margin: 50px 31px;        
    }
}

@media screen and (max-width: 600px) {
    .log__exmp1 {
        font-size: 48px;
    }    
}

.log__exmp1-card {
    width: 263px;
    height: 142px;
    margin: 10px 0px;
    border-radius: 10px;
    /* border: 1px solid #00F0FF; */
}

.log__exmp1-card:hover, 
.log__exmp1-card:focus {
    background: var(--background-emph-grad);
}

.log__exmp1-hbar {
    width: 108px;
    height: 7px;
    background: linear-gradient(90deg,  rgba(255, 7, 245, 1) 0%, rgba(0, 240, 255, 1) 100%);
    color: blueviolet;
    position: relative;
    margin: 11px;
}

.log__exmp1-inner1 {
    position: relative;
    margin: 11px;
    left: 0px;

    font-family: 'Advent Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: white;
    -webkit-text-fill-color: initial;

}


/****************************************************************************/

.log__tutorial {
    background: #CBFBC7;
    border: 1px solid #00F0FF;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
}

.log__tutorial p {
    margin: 11px;
    font-family: 'Andika New Basic';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 39px;
    white-space: pre-line;

    color: #000000;
}

.log__tutorial img {
    margin: 0px;
    color: #000000;
    border-radius: 10px;
    width: 100%
}

.log__tutorial {
    margin: 100px 61px;
    min-height: 225px;
    width: 1024px;
}

@media screen and (max-width: 1920px) {
    .log__tutorial {
        margin: 80px 61px;        
        width: 1024px;
    }
}

@media screen and (max-width: 1240px) {
    .log__tutorial {
        margin: 50px 31px;        
        width: calc(100vw - 75px);
    }

}

@media screen and (max-width: 900px) {
    .log__tutorial {
        margin: 50px 31px;        
        width: calc(100vw - 75px);
    }

}

@media screen and (max-width: 600px) {
    .log__tutorial {
        font-size: 48px;
        width: calc(100vw - 75px);
    }       
}

