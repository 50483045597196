.log__footer {
    display: flex;
    justify-content: space-evenly;
    margin: 30px;
    margin-bottom: 50px;
    color: rgba(100, 100, 100, 1);
    font-family: var(--font-family);
    font-size: 24px;
    align-items: center;
    gap: 20px;
    height: 30px; 
}

.log__footer-link {
    text-decoration: underline
}

.log__footer-link:hover,
.log__footer-link:focus {
  color: var(--pink-color);
  transition: all 0.2s ease;
}
