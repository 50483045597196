
/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
.log__intro {
    font-family: var(--font-family);
    font-weight: 700;

}

.log__intro h1 {
    font-size: 96px;
    background: var(--font-h1-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0px 61px;
    width: 350px;
}

.log__intro p {
    font-size: 64px;
    background: var(--font-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0px 61px;
    width: 900px;
}

.log__intro p {
    width: 1200px;
    margin: 40px 61px;
}

@media screen and (max-width: 1920px) {
    .log__intro p {
        width: calc(100vw - 650px);
        margin: 20px 61px;        
    }
}

@media screen and (max-width: 1240px) {
    .log__intro h1 {
        margin: 0px 31px;        
    }

    .log__intro p {
        width: calc(100vw - 200px);
        margin: 0px 31px;        
    }
}

@media screen and (max-width: 900px) {
    .log__intro p {
        width: calc(100vw - 100px);
        margin: 0px 31px;        
    }
}

@media screen and (max-width: 600px) {
    .log__intro p {
        width: calc(100vw - 65px);
        font-size: 48px;
    }

    .log__intro h1 {
        font-size: 64px;
    }    
}



/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
.log__intro-CTAspace {
    margin: 100px 61px;
}

@media screen and (max-width: 1920px) {
    .log__intro-CTAspace {
        margin: 100px 61px;        
    }
}

@media screen and (max-width: 1240px) {
    .log__intro-CTAspace {
        margin: 80px 31px;        
    }
}

@media screen and (max-width: 900px) {
    .log__intro-CTAspace {
        margin: 60px 31px;        
    }
}

@media screen and (max-width: 600px) {
    .log__intro-CTAspace {
        margin: 30px 31px;        
    }
}


.log__intro-text p {
    font-family: 'Advent Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: white;
    -webkit-text-fill-color: initial;
}