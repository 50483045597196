  /* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
.log__user {
    font-family: var(--font-family);
    font-weight: 700;

}

.log__user h1 {
    font-size: 96px;
    background: var(--font-h1-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0px 61px;
    width: 350px;
}

.log__user p {
    font-size: 64px;
    background: var(--font-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0px 61px;
    width: 900px;
}

.log__Authenticator_rtn_link {
    display: flex;
    justify-content: center;
    margin: var(--document-margin);
    color: white;
    font-family: var(--font-family);
    font-size: 24px;
    align-items: center;
    gap: 20px;
    height: 30px; 
    text-decoration: underline
}

.log__Authenticator_delete {
    background: #505050;
    margin: 0px;
    padding: 10px 0px;
}

.log__Authenticator_delete_text {
    margin: var(--document-margin);
    color: white;
    font-family: var(--font-family);
    font-size: 24px;
    gap: 20px;
    margin: 0px 61px;
}

.log__Authenticator_delete_text_x {
    display: flex;
    flex-direction: row-reverse;
    margin: var(--document-margin);
    color: white;
    font-family: var(--font-family);
    font-size: 24px;
    gap: 20px;
    margin: 0px 61px;
}



.log__Authenticator_rtn_link:hover,
.log__Authenticator_rtn_link:focus {
  color: var(--pink-color);
  transition: all 0.2s ease;
}


.log__CTAbtn1 {
    width: 359px;
    height: 80px;
    background: linear-gradient(90deg, rgba(193, 251, 255, 1) 0%, rgba(0, 240, 255, 1) 100%);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 61px;
}

.log__CTAbtn1:hover,
.log__CTAbtn1:focus {
    background: linear-gradient(90deg, rgba(193, 251, 255, 1) 0%, rgba(0, 240, 255, 1) 100%);
    background: linear-gradient(90deg, rgba(143, 201, 205, 1) 0%, rgba(0, 190, 205, 1) 100%); 
}

.log__CTAbtn-p1 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: black;
    -webkit-text-fill-color: initial;
    margin: 0px;
}

.log__CTAbtn2 {
    width: 359px;
    height: 80px;
    background: linear-gradient(-90deg,rgb(251, 255, 0) 0%, rgb(246, 255, 193) 100%);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 61px;

}

.log__CTAbtn2:hover,
.log__CTAbtn2:focus {
    background: linear-gradient(-90deg,rgb(251, 255, 0) 0%, rgb(246, 255, 193) 100%);
    background: linear-gradient(-90deg,rgb(201, 205, 0) 0%, rgb(196, 205, 143) 100%);
}

.log__CTAbtn-p2 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: black;
    -webkit-text-fill-color: initial;
    margin: 0px;
}

.log__CTAbtn3 {
    width: 359px;
    height: 80px;
    background: linear-gradient(90deg, rgb(250, 191, 191) 0%, rgb(255, 0, 0) 100%);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 61px;

}

.log__CTAbtn3:hover,
.log__CTAbtn3:focus {
    background: linear-gradient(90deg, rgb(250, 191, 191) 0%, rgb(255, 0, 0) 100%);
    background: linear-gradient(90deg, rgb(200, 141, 141) 0%, rgb(205, 0, 0) 100%);
}

.log__CTAbtn-p3 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: black;
    -webkit-text-fill-color: initial;
    margin: 0px;

}

.log__StripePricingTable{
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 20px;
}

stripe-pricing-table {
    border-radius: 20px;
}

.log__user ul {
    font-family: var(--font-family);
    color: var(--color-text);
}

.log__user li {
    font-family: var(--font-family);
    color: var(--color-text);
    margin-left: 20px;
    font-weight: initial;
}

.log__userA li {
    font-family: var(--font-family);
    color: var(--color-text);
    margin-left: 40px;
}

.log__user-consumptiontable {
    color: var(--color-text);
    margin-left: 0px;
}

a:hover,
a:focus {
  color: var(--pink-color);
  transition: all 0.2s ease;
}

p {
    color: var(--color-text);
    font-family: var(--font-family);
}