.log__CTAbox {
    display: flex;
    flex-direction: row ;
    border-radius: 10px;
    align-items: center;
    gap: 10px;
/*    flex-wrap: wrap;*/
    background: var(--background-emph-grad);
    min-height: 255px;
    font-family: var(--font-family);
    padding: 46px;
}

@media screen and (max-width: 900px) {
    .log__CTAbox {
        flex-direction: column ;
    }
}


.log__CTAbox-left {
    margin: 0px 0px;
    width: 800px;
}

.log__CTAbox-right {
    margin: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

@media screen and (max-width: 900px) {
    .log__CTAbox-left {
        width: unset;
    }
    .log__CTAbox-right {
        margin-top: 46px;
        width: unset;
    }
}

.log__CTAbox h1 {
    font-size: 64px;
    background: var(--font-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.log__CTAbox-txt {
    font-family: 'Advent Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: white;
    -webkit-text-fill-color: initial;
}


.log__CTAbox {
    margin: 100px 61px;
}

@media screen and (max-width: 1920px) {
    .log__CTAbox {
        margin: 80px 61px;        
    }
}

@media screen and (max-width: 1240px) {
    .log__CTAbox {
        margin: 50px 31px;        
    }
}

@media screen and (max-width: 900px) {
    .log__CTAbox {
        margin: 50px 31px;        
    }
}

@media screen and (max-width: 600px) {
    .log__CTAbox {
        font-size: 48px;
    }    
}

.log__CTAbox {
    width: calc(100vw - marginX);
}