.log__infobar--container_error {
    width: 100%; /* Ensure full width */
    height: 30px;
    background: #f2c114; /* Strong yellow background */
    position: fixed;
    left: 0; /* Align to the left edge */
    top: 0; /* Align to the top */
    z-index: 100;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content within the bar */
    border: 1px solid black;
    border-radius: 5px;
    box-sizing: border-box; /* Include padding and border in the width and height */
}

.log__infobar--container_error p {
    color:#a50a0a ;
    font-family: var(--font-family);
    font-weight: 700;
}

.log__infobar--container_info {
    width: 100%; /* Ensure full width */
    height: 30px;
    background: #14f227; /* Strong yellow background */
    position: fixed;
    left: 0; /* Align to the left edge */
    top: 0; /* Align to the top */
    z-index: 100;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content within the bar */
    border: 1px solid black;
    border-radius: 5px;
    box-sizing: border-box; /* Include padding and border in the width and height */
}

.log__infobar--container_info p {
    color:black ;
    font-family: var(--font-family);
    font-weight: 700;
}