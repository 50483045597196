
/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
.log__navbar {
    display: flex;
    justify-content: flex-end;
    margin: var(--document-margin);
    color: white;
    font-family: var(--font-family);
    font-size: 24px;
    align-items: center;
    gap: 20px;
    height: 30px; 
}

.log__navbar-link {
    text-decoration: underline
}

.log__navbar-link:hover,
.log__navbar-link:focus {
  color: var(--pink-color);
  transition: all 0.2s ease;
}

.log__navbar-icon {
    display: none;
}

.log__navbar-icon-anime {

}

.log__navbar-icon-anime:hover,
.log__navbar-icon-anime:focus {
    animation: animate-icon .3s forwards linear;
}

@keyframes animate-icon {
	0% {
		transform: rotate(0deg) ;
	}
	100% {
		transform: rotate(360deg) ;
	}
}



/* https://www.joshwcomeau.com/css/interactive-guide-to-flexbox/ */
.log__navbar-menu {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
    display: none;

    animation: animate-menu .3s linear;
}

.log__navbar-menu-disappear {
    display: flex;
    animation: animate-menu-bcg .3s forwards linear;
}

@keyframes animate-menu {
	0% {
		transform: translateY(-20px) scaleY(0);
		height: 0px ;
	}
	50% {
		transform: translateY(-20px) scaleY(.5);
		height: 82px ;
	}
	100% {
		transform: translateY(0px) scaleY(1);
		height: 185px ;
	}
}

@keyframes animate-menu-bcg {
	0% {
		transform: translateY(0px) scaleY(1);
		height: 185px ;
	}
	50% {
		transform: translateY(-20px) scaleY(.5);
		height: 82px ;
	}
	100% {
		transform: translateY(-20px) scaleY(0);
		height: 0px ;
	}
}



@media screen and (max-width: 600px) {
    .log__navbar {
        display: none;
    }

    .log__navbar.log__navbar-icon {
        display: flex;
    }

    .log__navbar.log__navbar-menu {
        display: flex;
        height: unset;
    }
    
}