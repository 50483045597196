.log__projects--create_dataset {
    margin: 10px 60px;
}

.log__projects--create_dataset-text:hover,
.log__projects--create_dataset-text:focus {
    color: var(--pink-color);
    transition: all 0.2s ease;
    cursor: pointer;
}


.log_projects--datasets_graphs__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 0 60px;
    /* height: 80px; */
    /* border: 1px solid #ff0000; */
    width: 1200px;
}

.log_projects--datasets_graphs__items {
    background-color: var(--background-color);
    background-image: var(--background-emph-grad);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 100%; /* Adjusted background size */

    color: white;
    text-align: center;
    border: 1pt;
    width: 50%;
    display: flex;           /* Enables flexbox */
    align-items: center;     /* Centers content vertically */
    justify-content: center; /* Centers content horizontally */

    position: relative;
}    

.log_projects--datasets_graphs__items p {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 64px;
    background: var(--font-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.log_projects--datasets_graphs__items:hover, 
.log_projects--datasets_graphs__items:focus {
    background-blend-mode: multiply, normal;

}

.log_projects--datasets_graphs__items-hbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7px;

    background: linear-gradient(90deg,  rgba(255, 7, 245, 1) 0%, rgba(0, 240, 255, 1) 100%);
    color: blueviolet;
}

.log_projects--datasets_graphs__items_selected {
    border: 1px solid #00f0ff;
}    

.log_projects--datasets_graphs__items_left {
    border-radius: 10px 0 0 0;
}

.log_projects--datasets_graphs__items_right {
    border-radius: 0 10px 0 0;    
}

