.log__graphcard {
    width: 322px;
    /* height: 400px; */
    /* margin: 10px; */
    border-radius: 10px;
    border: 1px solid #c1fbff;
    display: flex;
    flex-direction: column;
    background: var(--background-emph-grad);

    box-sizing: border-box; 
    /* width: 322px; */
    /* min-height: 320px; */
    margin: 10px;
    /* text-align: center; */
}

.log__graphcard p{
    margin: 10px;
}

.log__graphcard--graph {
    margin: 10px;
    width: 300px;
    height: 300px;
    overflow: hidden; /* Crop the image to fit within the container */
}

/* Style the image to fit within the container while preserving aspect ratio */
.log__graphcard--graph__img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Crop and center the image while preserving aspect ratio */

    border: 1px solid #c1fbff;
    border-radius: 10px;
}

.log__graphcard--graph-settings__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: -1;
}

.log__graphcard--graph-settings {
    color: white;
    background: var(--background-emph-grad);
    border: 1px solid #c1fbff;
    border-radius: 10px;
    z-index: 20;
    max-height: 95vh; /* Maximum height, e.g., 90% of the viewport height */


    /* Positioning and sizing */
    position: fixed; /* Use 'absolute' if you want it relative to a positioned parent */
    top: 10px;
    right: 10px;
    /*bottom: 10px;*/
    left: 10px;

    /* If you want the content to not touch the borders */
    padding: 10px; /* Adjust as needed */

    /* Optional: If you want to ensure the content inside is scrollable if it overflows */
    overflow: auto;
}



.log__graphdetail {
    /*    position: relative; */
    font-family: var(--font-family);
    font-weight: 700;
    color: white;
    /*height: 142px;*/
    display: flex;
    flex-direction: column;
    background: var(--background-emph-grad);
    /* min-height: 255px; */
    z-index: 10;
}

.log__graphdetail-header_box {
    position: relative;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #ff00ff; */
}

.log__graphdetail-header_box-title {
    font-size: 64px;
    background: var(--font-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    /* border: 1px solid #0088FF; */
    width: 700px;
}

.log__graphdetail--graph-element {
    display: flex; /* Use flexbox for layout */
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    overflow: hidden; /* Keeps any overflowing content hidden */
    flex-direction: column;
}

.log__graphdetail img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #c1fbff;
    border-radius: 10px;
}

.log__graphdetail--editor {
    border: 1px solid #c1fbff;
    height: 60vh;
}


.log__graphdetail--btn {
    width: 200px;
    height: 40px;
    margin: 10px;
}

.log__graphdetail--btn--center {
    margin: auto;
    margin-top: 10px;
}


.log__graphdetail--btn p {
    font-size: 24px;
}

.log__graphcard--settings {
    box-sizing: border-box;
    width: 450px;
    background: var(--background-emph-grad);
    border: 1px solid #c1fbff;
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 10px;
    z-index: 15;
  }

  .log__graphcard--settings p {
    margin: 10px;
  }

.log__graphdetail-link:hover,
.log__graphdetail-link:focus {
    color: var(--pink-color);
    transition: all 0.2s ease;
    cursor: pointer;
}

.log__graphdetail-link_p {
    margin: 10px;
}

.log__graphcard--settings--right-icon-container {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end; /* Align icon to the right */
    margin: 0px;
}

.log__graphdetail--settings--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 12;
}

.log__graphdetail--set-default-data-source {
    width: 650px;
    /* height: 300px; */
    background: var(--background-emph-grad);
    border: 1px solid #c1fbff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); /* Center the object */
    border-radius: 10px;
    z-index: 10;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: flex-start; /* Align items to the start (top) of the container */
    flex-direction: column;
    padding: 0px 20px 0px 0px;
}

.log__graphdetail--set-default-data-source table {
    width: 100%; /* Make table width 100% of its container */
    border-collapse: collapse; /* Optional: for a cleaner look */
}

.log__graphdetail--set-default-data-source th,
.log__graphdetail--set-default-data-source td {
    text-align: left; /* Align text to the left */
    padding: 3px 10px; /* Add some padding for aesthetics */
}

/* Optional: Add styles for the table header */
.log__graphdetail--set-default-data-source th {
    /* background-color: #f2f2f2; /* Light grey background for header */
    /* color: black; */
}

.log__graphdetail--set-default-data-source--right-icon-container {
    display: flex;
    justify-content: flex-end; /* Align icon to the right */
    width: 100%; /* Ensure the container spans full width */
}

.log__graphdetail--display-confirmation-duplicated-graph {
    width: 650px;
    /* height: 300px; */
    background: var(--background-emph-grad);
    border: 1px solid #c1fbff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); /* Center the object */
    border-radius: 10px;
    z-index: 10;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Align items to the start (top) of the container */
    flex-direction: column;
    padding: 0px 20px 0px 0px;
}

.log__graphdetail--display-confirmation-duplicated-graph--right-icon-container {
    display: flex;
    justify-content: flex-end; /* Align icon to the right */
    width: 100%; /* Ensure the container spans full width */
}

.log__graphdetail--delete_confirmation {
    width: 550px;
    height: 300px;
    background: var(--background-emph-grad);
    border: 1px solid #c1fbff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); /* Center the object */
    border-radius: 10px;
    z-index: 10;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    flex-direction: column;
}

.log__graphdetail--delete_confirmation--button-container {
    display: flex;
    justify-content: space-around;
    gap: 30px; /* Adjust the gap as needed */
}

.log__graphdetail--delete_confirmation--button {
    flex: 1; /* Makes both buttons take equal width */
    /* Additional styling for buttons */
    padding: 3px; /* Adjust padding as needed */
    width: 120px;
    /* Add more styling as per your design */
}

.log__graphdetail--delete_confirmation--button:hover,
.log__graphdetail--delete_confirmation--button:focus
{
    color: var(--pink-color);
    transition: all 0.2s ease;
    cursor: pointer;
}

.log__graphdetail--delete_confirmation--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
    z-index: 10; /* Below the confirmation dialog */
}

.log__graphdetail--editor-buttons--container {
    display: flex;
}

.log__graphcard--plus--container {
    display: flex;
    justify-content: space-around;
    color: white;
    align-items: center; /* Vertically center the content */
    height: 400px;
}

.log__graphcard--plus--container:hover
.log__graphcard--plus--container-circle,
.log__graphcard--plus--container:focus
.log__graphcard--plus--container-circle
{
    border: 1px solid var(--pink-color);
    transition: all .2s ease;
    cursor: pointer;
}

.log__graphcard--plus--container-circle {
    height: 60px;
    width: 60px;
    border-radius: 30px;

    display: flex;
    justify-content: space-around;
    align-items: center; /* Vertically center the content */
}
