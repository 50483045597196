.log__imageloader--loading {
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center text horizontally */
    width: 800px;
    height: 600px;
}

.log__imageloader--loading p {
    font-family: var(--font-family);
    color: black;
}

.log__imageloader--text {
    color: white;
}

.log__imageloader--error {
    color: white;
}