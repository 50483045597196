* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
  
  a {
    color: unset;
    text-decoration: none;
  }

  p {
    color: whitesmoke;
  }
  
  .log__bcgimg {
    position:relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 0px;
    filter: brightness(30%) contrast(110%) saturate(150%) grayscale(20%) hue-rotate(40deg);
    z-index: -1;
  }
  
  
  .log__bcgimg-full_width {
    display: none;
    position: relative;
    height: 0px;
    width: 100vw;
    filter: brightness(30%) contrast(110%) saturate(150%) grayscale(20%) hue-rotate(40deg);
    z-index: -2;
  }
  
  body {
    background-color: var(--background-color);
    background-image: var(--background-grad);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    background-attachment: scroll;
    z-index: 5;
  }
  
  @media screen and (max-width: 900px) {
    .log__bcgimg {
      display: none;
    }
  
    .log__bcgimg-full_width {
      display: block;
      height: 0px;
      z-index: -2;
    }
  }
  
  /* Responsive breaks:
  1920
  1240
  900
  600
  */