@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@200;300;400;500;600;700;800&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Andika+New+Basic:wght@200;300;400;500;600;700;800&display=swap'); */


:root {
  --font-family: 'Advent Pro';
  --background-grad: linear-gradient(154.02deg, #4C367A 1.45%, #222469 9.13%, #060015 45.18%);
  --background-color: #060015;
  --document-margin: 5px ;
  --color-text: #FFFFFF;
  --font-h1-grad: linear-gradient(180deg, rgba(170, 40, 40, 0.92) 0%, rgba(255, 7, 245, 0.92) 100%);
  --font-grad: linear-gradient(180deg, rgba(0, 240, 255, 0.92) 0%, rgba(255, 0, 245, 0.92) 100%);
  --pink-color: rgba(255, 7, 245, 0.92);
  --background-emph-grad: linear-gradient(89.51deg, #222469 0%, #1E1E1E 100%);
}

/*

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}
*/