.log__displaycsv {
    /* width: 300px; */
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #c1fbff;
    cursor: grab; /* Change cursor to indicate draggability */
    border-radius: 10px;
}

.log__displaycsv--table {
    border-collapse: separate;
    border-spacing: 12px 0px;
}

.log__displaycsv--table td {
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    vertical-align: middle;
}

.log__displaycsv--wide {
    /* width: 600px; /* Adjust as needed */
}

.log__displaycsv.active {
    cursor: grabbing;
}
