/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
.log__datasetcard {
/*    position: relative; */
    font-family: var(--font-family);
    font-weight: 700;
    color: white;
    width: 1200px; /* Adjusted width */
    /*height: 142px;*/
    margin: 10px 61px;
    border-radius: 10px;
    border: 1px solid #c1fbff;
    display: flex;
    flex-direction: column;
    background: var(--background-emph-grad);
    /* min-height: 255px; */
    width: calc(100vw - marginX);
}

.log__datasetcard-header_box {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 20px;
    /* border: 1px solid #ff00ff; */
}

.log__datasetcard-header_box-title {
    font-size: 64px;
    background: var(--font-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    /* border: 1px solid #0088FF; */
    width: 700px;
}

.log__datasetcard-header_box-key_info {
    width: 250px;
    /* border: 1px solid #00ffff; */
}

.log__datasetcard-link:hover,
.log__datasetcard-link:focus {
    color: var(--pink-color);
    transition: all 0.2s ease;
    cursor: pointer;
}

.log__datasetcard-link_p {
    margin: 10px;
}

.log__datasetcard-content_short {
    margin-right: 0px; /* Space for the icon */
    margin: 20px;
    margin-top: 0;
    /* border: 1px solid #0f0fff; */
}

.log__datasetcard-content_long {
    margin-right: 0px; /* Space for the icon */
    margin: 20px;
    margin-top: 0;
    /* border: 1px solid #0f0fff; */
}

.log__datasetcard--icon_unroll_down {
    /* border: 1px solid #00F0FF; */
    text-align: center;
}

.log__datasetcard--icon_unroll_down:hover,
.log__datasetcard--icon_unroll_down:focus
 {
    color: var(--pink-color);
    transition: all 0.2s ease;
    cursor: pointer;
 }

.log__datasetcard--settings {
  width: 450px;
  background: var(--background-emph-grad);
  border: 1px solid #c1fbff;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10px;
  z-index: 3;
}

.log__datasetcard--settings--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
}

.log__datasetcard--settings p {
    margin: 10px;
}

.log__datasetcard--settings li {
    margin: 10px;
}

.log__datasetcard--settings li::before {
    content: "- ";
}


.log__datasetcard--settings--inputfield {
    margin: 0px;
    width: 250px ;
}

.log__datasetcard--settings--inputfield__short {
    margin: 0px;
    width: 160px ;
}

.log__datasetcard--delete_confirmation {
    width: 450px;
    height: 300px;
    background: var(--background-emph-grad);
    border: 1px solid #c1fbff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); /* Center the object */
    border-radius: 10px;
    z-index: 10;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    flex-direction: column;
  }

.log__datasetcard--delete_confirmation--button-container {
    display: flex;
    justify-content: space-around;
    gap: 30px; /* Adjust the gap as needed */
}

.log__datasetcard--delete_confirmation--button {
    flex: 1; /* Makes both buttons take equal width */
    /* Additional styling for buttons */
    padding: 3px; /* Adjust padding as needed */
    width: 60px;
    /* Add more styling as per your design */
}

.log__datasetcard--delete_confirmation--button:hover,
.log__datasetcard--delete_confirmation--button:focus
 {
    color: var(--pink-color);
    transition: all 0.2s ease;
    cursor: pointer;
 }

 .log__datasetcard--delete_confirmation--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
    z-index: 9; /* Below the confirmation dialog */
  }


.log__datasetcard--set-default-data-source--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0); /* Semi-transparent black */
    z-index: 9; /* Below the confirmation dialog */
  }

  .log__datasetcard--set-default-data-source {
    width: 450px;
    height: 300px;
    background: var(--background-emph-grad);
    border: 1px solid #c1fbff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); /* Center the object */
    border-radius: 10px;
    z-index: 10;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: left; /* Vertically center the content */
    flex-direction: column;
    padding: 10px;
  }

  /*
    transform: translateX(-50%);



.log__datasetcard-topleft {
    display: flex;
    width: 600px;
    justify-content: flex-start;
    border: 1px solid #666666;
}

.log__datasetcard-leftcontainer {
    display: flex;
    width: 600px;
    justify-content: flex-start;
    border: 1px solid #006666;
}

.log__datasetcard-leftcontainer h1 {
    font-size: 64px;
    background: var(--font-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    width: 600px;
    justify-content: flex-start;
    border: 1px solid #330088;
}

.log__datasetcard-toptext {
    
    display: flex;    
    width: 600px;
    justify-content: flex-start;
    border: 1px solid #666666;
}



/*
.log__datasetcard h1 {
    font-size: 64px;
    background: var(--font-grad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0px 61px;
    border: 1px solid #ffc978;
}

.log__datasetcard p {
    font-family: 'Advent Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: white;
    -webkit-text-fill-color: initial;
    border: 1px solid #1fc978;
}

.log__datasetcard-topleft {
    display: flex;
    width: 600px;
    justify-content: flex-start;
    border: 1px solid #666666;
}

.log__datasetcard-refresh {
    margin: 0px;
    display: block;
    width: 10px;
    border: 1px solid #2288FF;
}

.log__datasetcard-toptext {
    display: block;
    border: 1px solid #22aaFF;
}

@media screen and (max-width: 1920px) {
    .log__datasetcard p {
        width: calc(100vw - 650px);
        margin: 20px 61px;        
    }
}

@media screen and (max-width: 1240px) {
    .log__datasetcard h1 {
        margin: 0px 31px;        
    }

    .log__datasetcard p {
        width: calc(100vw - 200px);
        margin: 0px 31px;        
    }
}

@media screen and (max-width: 900px) {
    .log__datasetcard p {
        width: calc(100vw - 100px);
        margin: 0px 31px;        
    }
}

@media screen and (max-width: 600px) {
    .log__datasetcard p {
        width: calc(100vw - 65px);
        font-size: 48px;
    }

    .log__datasetcard h1 {
        font-size: 64px;
    }    
}

*/
